import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Slider from "react-slick";
import Main from "./main";
import Tilt from "react-vanilla-tilt";
import { horizontalData, defaultOptions2 } from "./data";
import { throttle, handleScroll } from "./common";
import MouseSVG from "../mouse.svg";
import Contacts from "./contacts";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import { ConstructionSharp } from "@mui/icons-material";

const cookies = new Cookies();
class Horizontal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: "sag",
      fadeIn: false,
      fadeOut: false,
      leaveIn: false,
      leaveOut: false,
      mounting: false,
      screen: window.innerWidth,
      mobileData: [],
    };
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "ru";
    }
    this.sliderRef = React.createRef();
    this.throttle = throttle.bind(this);
    this.handleScroll = this.throttle(this.handleScroll.bind(this), 2000);
  }

  componentDidMount() {
    window.addEventListener("wheel", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.setState({ data: horizontalData, mobileData: horizontalData });
  }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ screen: window.innerWidth });
  };
  navGoTo(e) {
    const { data } = this.state;
    const { position } = this.state;
    if (e !== "sag" && e !== "contacts") {
      if (e > position || position == "sag") {
        this.setState({ fadeOut: true }, () => {
          setTimeout(() => {
            this.setState({ fadeOut: false }, () => {
              this.setState({ position: e }, () => {
                this.setState({ fadeIn: true }, () => {
                  setTimeout(() => {
                    this.setState({ fadeIn: false });
                  }, 1000);
                });
              });
            });
          }, 1000);
        });
      } else if (e < position || position == "contacts") {
        this.setState({ leaveIn: true }, () => {
          setTimeout(() => {
            this.setState({ leaveIn: false }, () => {
              this.setState({ position: e }, () => {
                this.setState({ leaveOut: true }, () => {
                  setTimeout(() => {
                    this.setState({ leaveOut: false });
                  }, 1000);
                });
              });
            });
          }, 1000);
        });
      }
    } else if (e == "sag") {
      this.setState({ leaveIn: true }, () => {
        setTimeout(() => {
          this.setState({ leaveIn: false }, () => {
            this.setState({ position: e }, () => {
              this.setState({ leaveOut: true }, () => {
                setTimeout(() => {
                  this.setState({ leaveOut: false });
                }, 1000);
              });
            });
          });
        }, 1000);
      });
    } else if (e == "contacts") {
      this.setState({ fadeOut: true }, () => {
        setTimeout(() => {
          this.setState({ fadeOut: false }, () => {
            this.setState({ position: e }, () => {
              this.setState({ fadeIn: true }, () => {
                setTimeout(() => {
                  this.setState({ fadeIn: false });
                }, 1000);
              });
            });
          });
        }, 1000);
      });
    }
  }
  handleScroll(event) {
    const deltaY = event.deltaY;
    const index = this.state.position;
    const screen = this.state.screen;

    if (screen > 850) {
      if (deltaY > 0) {
        if (index < this.state.data.length - 1) {
          this.navGoTo(index + 1);
        } else if (index == "sag") {
          this.navGoTo(0);
        } else {
          this.navGoTo("contacts");
        }
      } else if (deltaY < 0) {
        if (index > 0) {
          this.navGoTo(index - 1);
        } else if (index == "contacts") {
          this.navGoTo(this.state.data.length - 1);
        } else {
          this.navGoTo("sag");
        }
      }
    }
  }
  render() {
    const {
      data,
      position,
      fadeOut,
      fadeIn,
      leaveIn,
      leaveOut,
      screen,
      mobileData,
      id,
    } = this.state;
    const { t } = this.props;
    return (
      <div className={screen > 850 ? "" : "container"}>
        {screen > 850 ? (
          <div
            className={`horizontal content ${fadeOut ? "fade-out" : ""} ${
              leaveIn ? "leave-in" : ""
            } ${leaveOut ? "leave-out" : ""} ${fadeIn ? "fade-in" : ""} ${
              typeof position == "number" && "overlay"
            }`}
            onWheel={this.handleScroll}
          >
            <div className="horizontalSlider">
              {position == "sag" && (
                <React.Fragment>
                  <Main />
                </React.Fragment>
              )}
              {position == "contacts" && (
                <React.Fragment>
                  <Contacts />
                </React.Fragment>
              )}
              {data && position != "sag" && position != "contacts" && (
                <React.Fragment>
                  <span className="num">0{position + 1}</span>
                  <div className="hor-slides d-flex flex-nowrap justify-content-end align-items-center">
                    <div className="tilterTitles d-flex flex-column">
                      <h3>{data[position].blue[this.lang]}</h3>
                      <h2>{data[position].title}</h2>
                    </div>
                    <Link
                      to={"/inner/" + data[position].id}
                      className="d-flex flex-column justify-content-center"
                    >
                      <Tilt
                        options={defaultOptions2}
                        className="tilter"
                        style={{
                          background: `url(${data[position].image}) no-repeat`,
                          backgroundSize: "cover",
                        }}
                      >
                        {/* <div className="tiltoverlay">
                                {data[position].logo&&<img className="tilterLogo" src={data[position].logo}></img>}
                            </div> */}
                        <a
                          href={data[position].link}
                          className="tilterLink"
                          target="_blank"
                        >
                          {t("details.text")}
                        </a>
                      </Tilt>
                    </Link>
                  </div>
                </React.Fragment>
              )}
            </div>
            <nav className="vertical-nav mobile-hide">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 25 40"
                xmlSpace="preserve"
                className={`${position != "sag" && "disablesvg"}`}
              >
                <path d="M11,39.5C5.2,39.5,0.5,34.8,0.5,29V11C0.5,5.2,5.2,0.5,11,0.5h3c5.8,0,10.5,4.7,10.5,10.5v18c0,5.8-4.7,10.5-10.5,10.5H11z"></path>
                <circle cx="12.5" cy="11.5" r="1.5"></circle>
              </svg>
              <div className="before">
                <span
                  className={`${position == "sag" ? "active" : ""}`}
                  onClick={() => {
                    this.navGoTo("sag");
                  }}
                >
                  SAG
                </span>
                <div className="line"></div>
              </div>
              <ul>
                {data &&
                  data.map((item, i) => (
                    <li
                      className={`${i == position ? "active" : ""}`}
                      onClick={() => {
                        this.navGoTo(i);
                      }}
                      key={i}
                    >
                      0{i + 1}
                    </li>
                  ))}
              </ul>
              <div className="after">
                <div className="line"></div>
                <span
                  className={`${position == "contacts" ? "active" : ""}`}
                  onClick={() => {
                    this.navGoTo("contacts");
                  }}
                >
                  {t("contacts.text")}
                </span>
              </div>
            </nav>
          </div>
        ) : (
          <div className="mobile-content">
            <Main />
            <div className="vertical">
              {mobileData.map((data, i) => (
                <Link
                  to={"/inner/" + data.id}
                  className="vertical-content d-flex flex-column"
                >
                  <div className="vertical-titles d-flex flex-column">
                    <h3>{data.blue[this.lang]}</h3>
                    <h2>{data.title}</h2>
                  </div>
                  <img src={data.image} className="vertical-image" />
                </Link>
              ))}
            </div>
            <Contacts />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(Horizontal);
