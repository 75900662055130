export function throttle(func, delay) {
    let timeoutId;
    let lastExecTime = 0;

    return function (...args) {
      const context = this;
      const currentTime = Date.now();

      const execute = function () {
        func.apply(context, args);
        lastExecTime = currentTime;
      };

      if (currentTime - lastExecTime >= delay) {
        execute();
      }
    };
  }

// export function handleScroll(event) {
//     const deltaY = event.deltaY;
//     const index = this.state.position;
  
//     if (deltaY > 0) {
//       // if (index >= 0 && index < this.state.data.length - 1) {
//       //   this.setState({ fadeOut: true }, () => {
//       //     setTimeout(() => {
//       //       this.setState({ fadeOut: false }, () => {
//       //         this.setState({ position: this.state.position + 1 }, () => {
//       //           this.setState({ fadeIn: true }, () => {
//       //             setTimeout(() => {
//       //               this.setState({ fadeIn: false });
//       //             }, 1000);
//       //           });
//       //         });
//       //       });
//       //     }, 1000);
//       //   });
//       // }

//     } else if (deltaY < 0) {
//       if (index > 0 && index < this.state.data.length) {
//         this.setState({ leaveIn: true }, () => {
//           setTimeout(() => {
//             this.setState({ leaveIn: false }, () => {
//               this.setState({ position: this.state.position - 1 }, () => {
//                 this.setState({ leaveOut: true }, () => {
//                   setTimeout(() => {
//                     this.setState({ leaveOut: false });
//                   }, 1000);
//                 });
//               });
//             });
//           }, 1000);
//         });
//       }
//     }
//   }