import React from "react";
import { withTranslation } from "react-i18next";
import { ScrollContainer, ScrollPage } from "./scroll-motion";
import { timelines } from "./data";
import { useLocation, Link } from "react-router-dom";

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        // {
        //   id: 1,
        //   title: "Collection of Premium carpets Regalia",
        //   subtitle:
        //     "REGALIA is pleased to present its new showroom in the city of Samarkand, where you can discover an incredible combination of python skin and Swarovski crystals in every acrylic carpet.",
        //   is_active: true,
        //   content:
        //     "<p>The unique design and innovative production technologies ensure the highest quality and reliability, as well as the unique style and sophistication that are the hallmark of the REGALIA brand. These acrylic carpets will surprise you with their colors and patterns, which are an indispensable element of any interior. They create a unique atmosphere of comfort and luxury in any room and emphasize the individuality and status of the owner. Visit our showroom and make sure of the quality of our products and the high level of service. REGALIA is a place where luxury and elegance meet innovation and technology to create the best products for you and your home.</p>",
        //   image: "https://www.sag.uz/image/news_1683116411.jpg",
        // },
      ],
      file: null,
    };
  }

  componentDidMount() {}
  componentWillUnmount() {}
  render() {
    const { data } = this.state;
    const { t } = this.props;
    return (
      <div className="content container">
        <h2 className="blocktitle mb-2 pb-4">
          {" "}
          <span>{t("news.text")}</span>
        </h2>
        <div className="d-flex flex-wrap">
          {data.map((news, i) => (
            <Link
              to={"./news/" + news.id}
              className="news jobs col-lg-6 col-12"
              style={{ backgroundImage: `url(${news.image})` }}
              key={i}
            >
              <div className="jobs-content px-4 py-2 d-flex flex-column justify-content-center news-content">
                <p className="card-title mb-2">{news.title}</p>
                <p className="card-text">{news.subtitle}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(News);
