import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ScrollContainer, ScrollPage } from "./scroll-motion";
import { timelines } from "./data";
import { useLocation, Link } from "react-router-dom";

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        // {
        //   id: 1,
        //   position: "Programmist",
        //   title: "Front-end developer",
        //   is_active: true,
        //   requirement:
        //     "<p>Junior maoshiga ishlaydigan Senior dasturchi kerak!!!</p>",
        //   brand: 2,
        //   image:
        //     "https://dpnsee.org/wp-content/uploads/2020/01/Job-vacancy.jpg",
        // },
        // {
        //   id: 2,
        //   position: "Programmist",
        //   title: "Back-end developer",
        //   is_active: true,
        //   requirement:
        //     "<p>Junior maoshiga ishlaydigan Senior dasturchi kerak!!!</p>",
        //   brand: 2,
        //   image:
        //     "https://dpnsee.org/wp-content/uploads/2020/01/Job-vacancy.jpg",
        // },
      ],
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}
  render() {
    const { data } = this.state;
    const { t } = this.props;
    return (
      <div className="content container">
        <h2 className="blocktitle mb-4 pb-4">{t("career.text")}</h2>
        <div className="d-flex flex-wrap">
          {data.map((job, i) => (
            <Link
              to={"./career/" + job.id}
              className="jobs col-lg-6 col-12"
              style={{ backgroundImage: `url(${job.image})` }}
              key={i}
            >
              <div className="jobs-content px-4 py-2">
                <p className="card-title">{job.position}</p>
                <p className="card-text">{job.title}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Careers);
