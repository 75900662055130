import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Slider from "react-slick";
// import Main from "./main";
import Tilt from "react-vanilla-tilt";
import { innerData, defaultOptions2 } from "./data";
import { throttle, handleScroll } from "./common";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import MouseSVG from "../mouse.svg";
import Contacts from "./contacts";

const cookies = new Cookies();

class SliderContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      fadeIn: false,
      fadeOut: false,
      leaveIn: false,
      leaveOut: false,
      mounting: false,
      screen: window.innerWidth,
      mobileData: [],
      id: 0,
    };
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "ru";
    }
    this.sliderRef = React.createRef();
    this.throttle = throttle.bind(this);
    this.handleScroll = this.throttle(this.handleScroll.bind(this), 2000);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    window.addEventListener("wheel", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.setState({
      data: innerData[this.lang][id],
      mobileData: innerData[this.lang][id],
      id: id,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ screen: window.innerWidth });
  };
  navGoTo(e) {
    const { position, data } = this.state;
    console.log(position);
    if (e > position && e < data.length) {
      this.setState({ fadeOut: true }, () => {
        setTimeout(() => {
          this.setState({ fadeOut: false }, () => {
            this.setState({ position: e }, () => {
              this.setState({ fadeIn: true }, () => {
                setTimeout(() => {
                  this.setState({ fadeIn: false });
                }, 1000);
              });
            });
          });
        }, 1000);
      });
    } else if (e < position && e >= 0) {
      this.setState({ leaveIn: true }, () => {
        setTimeout(() => {
          this.setState({ leaveIn: false }, () => {
            this.setState({ position: e }, () => {
              this.setState({ leaveOut: true }, () => {
                setTimeout(() => {
                  this.setState({ leaveOut: false });
                }, 1000);
              });
            });
          });
        }, 1000);
      });
    }
  }
  handleScroll(event) {
    const deltaY = event.deltaY;
    const index = this.state.position;
    const screen = this.state.screen;

    if (screen > 1100) {
      if (deltaY > 0) {
        this.navGoTo(index + 1);
      } else if (deltaY < 0) {
        this.navGoTo(index - 1);
      }
    }
  }
  render() {
    const {
      data,
      position,
      fadeOut,
      fadeIn,
      leaveIn,
      leaveOut,
      screen,
      mobileData,
      id,
    } = this.state;
    return (
      <div className={screen > 1100 ? "" : "container"}>
        {screen > 850 ? (
          <div
            className={`horizontal content ${fadeOut ? "fade-out" : ""} ${
              leaveIn ? "leave-in" : ""
            } ${leaveOut ? "leave-out" : ""} ${fadeIn ? "fade-in" : ""} ${
              typeof position == "number" && "overlay"
            }`}
            onWheel={this.handleScroll}
          >
            <div className="horizontalSlider">
              {data && (
                <React.Fragment>
                  <span className="num">0{position + 1}</span>
                  <div className="hor-slides d-flex flex-nowrap justify-content-end  align-items-center">
                    <div className="tilterTitles d-flex flex-column tilterWhite">
                      <span>{data[position].blue}</span>
                    </div>
                    <Tilt
                      options={defaultOptions2}
                      className="tilter"
                      style={{
                        background: `url(${data[position].image}) no-repeat`,
                        backgroundSize: "cover",
                      }}
                    ></Tilt>
                  </div>
                </React.Fragment>
              )}
            </div>
            <nav className="vertical-nav mobile-hide">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 25 40"
                xmlSpace="preserve"
                className={`${position != "sag" && "disablesvg"}`}
              >
                <path d="M11,39.5C5.2,39.5,0.5,34.8,0.5,29V11C0.5,5.2,5.2,0.5,11,0.5h3c5.8,0,10.5,4.7,10.5,10.5v18c0,5.8-4.7,10.5-10.5,10.5H11z"></path>
                <circle cx="12.5" cy="11.5" r="1.5"></circle>
              </svg>
              <div className="before">
                <div className="line"></div>
              </div>
              <ul>
                {data &&
                  data.map((item, i) => (
                    <li
                      className={`${i == position ? "active" : ""}`}
                      onClick={() => {
                        this.navGoTo(i);
                      }}
                      key={i}
                    >
                      0{i + 1}
                    </li>
                  ))}
              </ul>
              <div className="after">
                <div className="line"></div>
              </div>
            </nav>
          </div>
        ) : (
          <div className="mobile-content">
            <div className="vertical">
              {mobileData.map((data, i) => (
                <div>
                  <div className="vertical-titles d-flex flex-column text-center">
                    <span className="mb-4 mt-4">{data.blue}</span>
                    {/* <h2>{data.title}</h2> */}
                  </div>
                  <img src={data.image} className="vertical-image mb-4 w-100" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SliderContent;
