import React from "react";
import {ScrollContainer, ScrollPage} from "./scroll-motion";
import {timelines} from './data'
import { useLocation } from 'react-router-dom'

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        data:{},
      };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    const data = timelines.find(obj => obj.id == id);
    this.setState({data:data});
  }
  

  componentWillUnmount() {
  }
  render() {
    const {data} = this.state;
    return (
        <div className="timeContent content">
            <div className="container">
                {
                data&&
                <div dangerouslySetInnerHTML={{__html:data.content}} className="dangerousHTML"/>
                }
            </div>
        </div>
    );
  }
}

export default Content;
