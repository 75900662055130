import React from "react";
import { withTranslation } from "react-i18next";
import sms from "../images/sms.svg";
import clock from "../images/clock.svg";
import location from "../images/location.svg";

class Dealers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: window.innerWidth,
      notificationMessage: null,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ screen: window.innerWidth });
  };

  render() {
    const { screen } = this.state;
    return (
      <div
        className={
          screen > 1400
            ? `contacts content item active`
            : `contacts content item active container`
        }
      >
        {screen > 500 ? (
          <div className="contactsblock flex-wrap">
            <div className="container">
              <h2>АНДИЖАН</h2>
              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG”, Адрес: ул. Boburshox,
                  <p> 6 A Ориентир: Makro super Market.</p>
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (90) 760 02 20,</span>
                  <br />
                  <span>+998 (91) 615 02 20</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: ул. Мустакилик ,
                  <p> 21 Ориентир: Стадион Спортивного колледжа.</p>
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (90) 760 02 20, </span>
                  <br />
                  <span>+998 (91) 615 02 20</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG Xasanboу ota”,
                  <p>Адрес: ул.Лойихавий Машраб , 1 Ориентир: Navruz Mall</p>
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (74) 227 66 67</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG Xasanboу ota”, Адрес: ул. Кушарик дадаси, 1
                  Ориентир: 9 этаж
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (74) 237 05 65</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG Xasanboу ota”,
                  <p>Адрес: л. Кушарик дахаси , 4 Ориентир: Dinar tex </p>
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (74) 237 16 66</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG» Адрес: ул.Амир Темур Шох 5.
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (93) 781 55 55</span>
                </div>
              </div>
              <hr />
              <h2>БЕКАБАД</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: 13-Микрорайон,
                  <p>ул. Амира Темура. Ориентир: Городской Бекабадский АТС.</p>
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (90) 093 96 96,</span>
                  <br />
                  <span>+998 (90) 961 12 32</span>
                </div>
              </div>
              <hr />

              <h2>БУХАРА</h2>
              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG”, Адрес: ул. Алпомиш 1.
                  <p>Ориентир: 6 Микрорайон, Спорт колледж. </p>
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (93) 136 44 44,</span>
                  <br />
                  <span>+998 (88) 855 44 44 ,</span>
                  <br />
                  <span>+998 (91) 244 88 80</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG” Адрес: ул.Ибн сино 49 , ориентир: Пахтакор
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span> Тел: +998 (90) 635-22-02</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG” Адрес: ул.Ибн сино , ориентир: Корзинка 2
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span> Тел: +998 (98) 774 37 33</span>
                </div>
              </div>
              <hr />

              <h2>ГУЛИСТАН</h2>
              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: ул. Тошкент йули, Старый оптовый рынок.
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (98) 560 20 09</span>
                </div>
              </div>
              <hr />

              <h2>ЖИЗЗАХ</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: Махалля Кахрамон. Ориентир: Метан
                  заправка
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (91) 565 14 14,</span>
                  <br />
                  <span>+998 (90) 516 00 05</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: Махалля Касабли, ул. Шарофа Рашидова.
                  Ориентир: Ресторан " Asr".
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (90) 516 00 05</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG”, Адрес: Джизакский рынок
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (90) 296 11 11,</span>
                </div>
              </div>
              <hr />

              <h2>КАРАКАЛПАК</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG” Адрес: г.Нукус ул.Е.Алакоз 171/2
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>Тел: +998 (61) 224 02 22;</span>
                  <br />
                  <span>+998 (99) 561 05 55</span>
                </div>
              </div>
              <hr />

              <h2>КАРШИ</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG”, Адрес: ул. Ислом Каримов, Карликхона, 305
                  Ориентир: супермаркет “Дустлик”,
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (91) 220 05 05</span>
                  <br />
                  <span>+998 (98) 520 15 55</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG”, Адрес: ул. Богобод МФЙ 3/3 Ориентир: Напротив
                  рынка Еркурган
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (93) 930 48 48,</span>
                  <br />
                  <span> +998 (91) 963 10 00 </span>
                </div>
              </div>
              <hr />

              <h2>КОКАНД</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «VODIY SAG Gilamlari », ул. А.Навоий, 147А
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (98) 558 25 85</span>
                </div>
              </div>
              <hr />

              <h2>НАВОИЙ</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: ул. Бештут 7А
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span> тел: +998 (93) 085 04 40 ;</span>
                  <br />
                  <span> +998 (90) 730 10 00;</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: ул. Галаба шох кучаси, 197 Ориентир:
                  вещевой рынок.
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (90) 730 33 96,</span>
                  <br />
                  <span>+ 998 (97) 797 88 48</span>
                </div>
              </div>
              <hr />

              <h2>НАМАНГАН</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: ул. Гирвонсой, 11. Ориентир: Мелкобинат
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: + 998 (93) 914 75 70,</span>
                  <br />
                  <span>+ 998 (93) 938 92 92</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: Гирвон базар. Ориентир: возле дома
                  "ELOBOD CITY"
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (90) 552 51 12,</span>
                  <br />
                  <span>+998 (93) 405 77 55</span>
                </div>
              </div>
              <hr />

              <h2>САМАРКАНД</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин SAG EXPRESS , Адрес: ул. Спитаменшох 270 , Ориентир:
                  Лифтостроительный завод
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (95) 500 72 72,</span>
                  <br />
                  <span>+998 (90) 285 77 11</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG”, Адрес: ул. Ибн Сино 104 , Ориентир: Автосалон
                  Regal
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (90) 602 83 83,</span>
                  <br />
                  <span>+998 (90) 280 73 73</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG” , Адрес: ул. Рудакий 151 , Ориентир: «Uzgazoil»
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (91) 541 55 55;</span>
                  <br />
                  <span>+998 (90) 212 00 40</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG” Адрес: ул. Рудакий 192 , Ориентир: кирпич. завод
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (91) 700 43 43,</span>
                  <br />
                  <span>+998 (88) 925 77 77</span>
                </div>
              </div>
              <hr />

              <h2>ТАШКЕНТ</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG”, Адрес: массив Кушбеги, ул. Малая кольцевая
                  дорога, 14 А.
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (91) 201 01 01 ,</span>
                  <br />
                  <span>+998 (90) 850 84 00</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  "SAG" GILAM BAZA, Адрес: Чиланзарский район, ул. Богистон 4А.
                  Ориентир: Жуковская больница (Неотложка)
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (95) 196 00 88,</span>
                  <br />
                  <span>+998 (93) 389 15 15,</span>
                  <br />
                  <span>+998 (94) 662 00 70</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин «SAG», Адрес: Учтепинский район, Малая кольцевая
                  дорога, дом 118, (Арча куча) Ориентир: 300 метров после моста
                  Шофайз
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (95) 196 15 15 ,</span>
                  <br />
                  <span>+998 (93) 503 77 07</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG GILAMLAR DUNYOSI”, Адрес: ул. Куксарой. Ориентир:
                  напротив Тахта базара.
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (95) 197-92-92,</span>
                  <br />
                  <span>+998 (97) 111 92 92</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG 55555”, Адрес: ул. Ташкентская кольцевая
                  автодорога. Ориентир: напротив торг. комплекса Бек-тупи.
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (95) 177-79-92,</span>
                  <br />
                  <span> +998 (90) 926-14-44,</span>
                  <br />
                  <span>+998 (99) 859 03 05 </span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  “SAG”, Адрес: Кибрайский район, махалля “Чинобод”, ул.
                  Бардавом 27.
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (99) 881 11 33,</span>
                  <br />
                  <span>+998 (99) 000 80 64,</span>
                  <br />
                  <span>+998 (99) 806 55 66 </span>
                </div>
              </div>
              <hr />

              <h2>ТЕРМИЗ</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин SAG EXPRESS, Адрес: ул. Тараккиёт, 47А Ориентир:
                  Restoran Grand Almaz
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (97) 895 07 70</span>
                </div>
              </div>
              <hr />

              <h2>УРГУТ</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин "SAG", Адрес: Махалля "Mergancha" , Ориентир: напротив
                  торгового комплекса Ургут.
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (94) 283 74 44 ,</span>
                  <br />
                  <span>+998 (93) 680 19 49</span>
                </div>
              </div>
              <hr />

              <h2>УРГЕНЧ</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин SAG EXPRESS , Адрес: ул. И Дустов, 114. Ориентир:
                  Инфекционная больница
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (99) 961 55 55</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Шоурум SAG EXPRESS, Адрес: ул. Ёгду 10 А. Ориентир: Старая
                  баня
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (93) 284 85 50</span>
                </div>
              </div>

              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG”, Адрес: ул. Фидокорлар пр.1, дом 1. Ориентир:
                  Вещевой рынок
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (93) 611-55-55,</span>
                  <br />
                  <span>+998 (90) 713-88-88</span>
                </div>
              </div>
              <hr />

              <h2>ФЕРГАНА</h2>

              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин SAG EXPRESS, Адрес: ул. Жойдам,24. Ориентир: рядом с
                  UzGazOil
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (88) 271 04 04</span>
                </div>
              </div>
              <hr />

              <h2>ХИВА</h2>
              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Magazin SAG EXPRESS "Xiva gilamlari" , Адрес: ул. Элобод 7
                  (Хивинский ковровый комбинат)
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span> тел: +998 (99) 122 72 00</span>
                </div>
              </div>
              <hr />

              <h2>ШАХРИСАБЗ</h2>
              <div className="row align-items-center">
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  <span>Адрес</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  <span>Часы работы</span>
                </div>
                <div className="col text-center mb-3">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="sms"
                    width="20"
                  />
                  <span>Наши магазины</span>
                </div>
              </div>
              <div className="row blur-background align-items-center">
                <div className="col text-center">
                  Магазин “SAG”, Адрес: ул. Ипак йули Школа Темуридов, рядом с
                  кафе "Favvora".
                </div>
                <div className="col text-center">Пн-Вс. 10:00-21:00</div>
                <div className="col text-center">
                  <span>тел: +998 (99) 668 32 72</span>
                  <br />
                  <span>тел: +998 (91) 321 05 59</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            <h2>АНДИЖАН</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG”, Адрес: ул. Boburshox , 6 A Ориентир: Makro
                  super Market.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (90) 760 02 20,</span>
                <span class="info">+998 (91) 615 02 20</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG», Адрес: ул. Мустакилик ,21 Ориентир: Стадион
                  Спортивного колледжа.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (90) 760 02 20,</span>
                <span class="info">+998 (91) 615 02 20</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG Xasanboу ota”, Адрес: ул.Лойихавий Машраб , 1
                  Ориентир: Navruz Mall
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (74) 227 66 67</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG Xasanboу ota”, Адрес: ул. Кушарик дадаси, 1
                  Ориентир: 9 этаж
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (74) 237 05 65</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG Xasanboу ota”, Адрес: л. Кушарик дахаси , 4
                  Ориентир: Dinar tex
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (74) 237 16 66</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG» Адрес: ул.Амир Темур Шох 5.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (93) 781 55 55</span>
              </div>
            </div>
            <hr />
            <h2>БЕКАБАД</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG», Адрес: 13-Микрорайон, ул. Амира Темура.
                  Ориентир: Городской Бекабадский АТС.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (90) 093 96 96,</span>
                <span class="info">+998 (90) 961 12 32</span>
              </div>
            </div>
            <hr />
            <h2>БУХАРА</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG”, Адрес: ул. Алпомиш 1. Ориентир: 6 Микрорайон,
                  Спорт колледж.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (93) 136 44 44,</span>
                <span class="info">+998 (88) 855 44 44 ,</span>
                <span class="info">+998 (91) 244 88 80</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG” Адрес: ул.Ибн сино 49 , ориентир: Пахтакор
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info"> Тел: +998 (90) 635-22-02</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG” Адрес: ул.Ибн сино , ориентир: Корзинка 2
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info"> Тел: +998 (98) 774 37 33</span>
              </div>
            </div>
            <hr />
            <h2>ГУЛИСТАН</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG», Адрес: ул. Тошкент йули, Старый оптовый рынок.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (98) 560 20 09</span>
              </div>
            </div>

            <hr />
            <h2>ЖИЗЗАХ</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG», Адрес: Махалля Кахрамон. Ориентир: Метан
                  заправка
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (91) 565 14 14,</span>
                <span class="info">+998 (90) 516 00 05</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG», Адрес: Махалля Касабли, ул. Шарофа Рашидова.
                  Ориентир: Ресторан " Asr".
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (90) 516 00 05</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">Магазин “SAG”, Адрес: Джизакский рынок</span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (90) 296 11 11,</span>
              </div>
            </div>

            <hr />
            <h2>КАРАКАЛПАК</h2>
            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG” Адрес: г.Нукус ул.Е.Алакоз 171/2
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">Тел: +998 (61) 224 02 22;</span>
                <span class="info">+998 (99) 561 05 55</span>
              </div>
            </div>

            <hr />
            <h2>КАРШИ</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG”, Адрес: ул. Ислом Каримов, Карликхона, 305
                  Ориентир: супермаркет “Дустлик”,
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (91) 220 05 05</span>
                <span class="info">+998 (98) 520 15 55</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG”, Адрес: ул. Богобод МФЙ 3/3 Ориентир: Напротив
                  рынка Еркурган
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (93) 930 48 48,</span>
                <span class="info">+998 (91) 963 10 00</span>
              </div>
            </div>

            <hr />
            <h2>КОКАНД</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «VODIY SAG Gilamlari », ул. А.Навоий, 147А
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (98) 558 25 85</span>
              </div>
            </div>

            <hr />
            <h2>НАВОИЙ</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">Магазин «SAG», Адрес: ул. Бештут 7А</span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (93) 085 04 40 ;</span>
                <span class="info">+998 (90) 730 10 00;</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG», Адрес: ул. Галаба шох кучаси, 197 Ориентир:
                  вещевой рынок.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (90) 730 33 96,</span>
                <span class="info">+ 998 (97) 797 88 48</span>
              </div>
            </div>

            <hr />
            <h2>НАМАНГАН</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG», Адрес: ул. Гирвонсой, 11. Ориентир: Мелкобинат
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: + 998 (93) 914 75 70,</span>
                <span class="info">+ 998 (93) 938 92 92</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG», Адрес: Гирвон базар. Ориентир: возле дома
                  "ELOBOD CITY"
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (90) 552 51 12,</span>
                <span class="info">+998 (93) 405 77 55</span>
              </div>
            </div>

            <hr />
            <h2>САМАРКАНД</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин SAG EXPRESS , Адрес: ул. Спитаменшох 270 , Ориентир:
                  Лифтостроительный завод
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (95) 500 72 72,</span>
                <span class="info">+998 (90) 285 77 11</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG”, Адрес: ул. Ибн Сино 104 , Ориентир: Автосалон
                  Regal
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (90) 602 83 83,</span>
                <span class="info">+998 (90) 280 73 73</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG” , Адрес: ул. Рудакий 151 , Ориентир: «Uzgazoil»
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (91) 541 55 55;</span>
                <span class="info">+998 (90) 212 00 40</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG” Адрес: ул. Рудакий 192 , Ориентир: кирпич. завод
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (91) 700 43 43,</span>
                <span class="info">+998 (88) 925 77 77</span>
              </div>
            </div>

            <hr />
            <h2>ТАШКЕНТ</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG”, Адрес: массив Кушбеги, ул. Малая кольцевая
                  дорога, 14 А.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (91) 201 01 01 ,</span>
                <span class="info">+998 (90) 850 84 00 </span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  "SAG" GILAM BAZA, Адрес: Чиланзарский район, ул. Богистон 4А.
                  Ориентир: Жуковская больница (Неотложка)
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (95) 196 00 88,</span>
                <span class="info">+998 (93) 389 15 15,</span>
                <span class="info">+998 (94) 662 00 70</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин «SAG», Адрес: Учтепинский район, Малая кольцевая
                  дорога, дом 118, (Арча куча) Ориентир: 300 метров после моста
                  Шофайз
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (95) 196 15 15 ,</span>
                <span class="info">+998 (93) 503 77 07</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG GILAMLAR DUNYOSI”, Адрес: ул. Куксарой. Ориентир:
                  напротив Тахта базара.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (95) 197-92-92,</span>
                <span class="info">+998 (97) 111 92 92</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG 55555”, Адрес: ул. Ташкентская кольцевая
                  автодорога. Ориентир: напротив торг. комплекса Бек-тупи.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (95) 177-79-92,</span>
                <span class="info">+998 (90) 926-14-44,</span>
                <span class="info">+998 (99) 859 03 05</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  “SAG”, Адрес: Кибрайский район, махалля “Чинобод”, ул.
                  Бардавом 27.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (99) 881 11 33,</span>
                <span class="info">+998 (99) 000 80 64,</span>
                <span class="info">+998 (99) 806 55 66 </span>
              </div>
            </div>

            <hr />
            <h2>ТЕРМИЗ</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин SAG EXPRESS, Адрес: ул. Тараккиёт, 47А Ориентир:
                  Restoran Grand Almaz
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (97) 895 07 70</span>
              </div>
            </div>

            <hr />
            <h2>УРГУТ</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин "SAG", Адрес: Махалля "Mergancha" , Ориентир: напротив
                  торгового комплекса Ургут.
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (94) 283 74 44 ,</span>
                <span class="info">+998 (93) 680 19 49</span>
              </div>
            </div>

            <hr />
            <h2>УРГЕНЧ</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин SAG EXPRESS , Адрес: ул. И Дустов, 114. Ориентир:
                  Инфекционная больница
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (99) 961 55 55</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Шоурум SAG EXPRESS, Адрес: ул. Ёгду 10 А. Ориентир: Старая
                  баня
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (93) 284 85 50</span>
              </div>
            </div>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG”, Адрес: ул. Фидокорлар пр.1, дом 1. Ориентир:
                  Вещевой рынок
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (93) 611-55-55,</span>
                <span class="info">+998 (90) 713-88-88</span>
              </div>
            </div>

            <hr />
            <h2>ФЕРГАНА</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин SAG EXPRESS, Адрес: ул. Жойдам,24. Ориентир: рядом с
                  UzGazOil
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (88) 271 04 04</span>
              </div>
            </div>

            <hr />
            <h2>ХИВА</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Magazin SAG EXPRESS "Xiva gilamlari" , Адрес: ул. Элобод 7
                  (Хивинский ковровый комбинат)
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info"> тел:+998 (99) 122 72 00</span>
              </div>
            </div>

            <hr />
            <h2>ШАХРИСАБЗ</h2>

            <div class="dealers-card">
              <div class="address">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={location}
                    alt="location"
                    width="20"
                  />
                  Адрес:
                </span>
                <span class="info">
                  Магазин “SAG”, Адрес: ул. Ипак йули Школа Темуридов, рядом с
                  кафе "Favvora".
                </span>
              </div>
              <div class="working-hours">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={clock}
                    alt="clock"
                    width="20"
                  />
                  Часы работы:
                </span>
                <span class="info">Пн-Вс. 10:00-21:00</span>
              </div>
              <div class="phones">
                <span class="label">
                  <img
                    style={{ marginRight: "10px" }}
                    src={sms}
                    alt="clock"
                    width="20"
                  />
                  Наши магазины:
                </span>
                <span class="info">тел: +998 (99) 668 32 72</span>
                <span class="info">тел: +998 (91) 321 05 59</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(Dealers);
