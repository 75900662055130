import React from "react";
import { notification } from "antd";
import { useEffect } from "react";

const Notification = ({ message, t }) => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    openNotification("bottomRight");
  }, []);
  const openNotification = (placement) => {
    api.info({
      message: `Notification`,
      description: message,
      placement,
      duration: 3,
    });
  };

  return <>{contextHolder}</>;
};
export default Notification;
