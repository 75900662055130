import React from "react";
import  Tilt  from 'react-vanilla-tilt'
import {defaultOptions2} from './data'
import {throttle} from './common'
export class ScrollContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        startDelta:this.props.startDelta,
        delta: this.props.startDelta,
        offset:1000,
        children: [],
        perspective:2000,
      };
      this.throttle = throttle.bind(this);
      this.handleScroll = this.throttle(this.handleScroll.bind(this), this.props.offset*1.2);
    }
  
    componentDidMount() {
      window.addEventListener('wheel', this.handleScroll);
      var {startDelta, offset, perspective} = this.state;
      const {children} = this.props;
      if(this.props.perspective){perspective=this.props.perspective;} 
      if(this.props.startDelta){startDelta=this.props.startDelta}
      if(this.props.offset){offset=this.props.offset}
      this.setState({ children: children, offset:offset, startDelta:startDelta, perspective:perspective });
    }
  
    componentWillUnmount() {
      window.removeEventListener('wheel', this.handleScroll);
    }
  
    handleScroll(event) {
        const deltaY = event.deltaY;
        const { delta, children, offset, startDelta } = this.state;
    
        const maxDelta = (children.length - 1) * - offset;
      
        let newDelta = delta;
        // console.log("deltaY="+deltaY, "maxDelta="+maxDelta, "newDelta="+newDelta, "delta="+delta);
        if (deltaY > 0 && delta-startDelta > maxDelta) {
          newDelta = delta - offset;
        } else if (deltaY < 0 && delta < 0) {
          newDelta = delta + offset;
        }
        // console.log("deltaY="+deltaY, "maxDelta="+maxDelta, "newDelta="+newDelta, "delta="+delta);
        this.setState({ delta: newDelta });
      }
  
      render() {
        const { children, delta, offset, perspective } = this.state;
        let childrenArray = children;
      
        if (!Array.isArray(children)) {
          childrenArray = [children]; // Convert single child into an array
        }
      
        return (
          <div className="ScrollContainer" style={{ perspective: `${perspective}px` }}>
            {childrenArray.map((child, index) => (
              <div
                className={`ScrollPage`}
                key={index}
                style={{
                  transform: `translate(-50%, -50%) translateZ(${delta + index * offset}px)`,
                  left: index % 2 === 0 ? '40%' : '60%',
                }}
              >
                {child}
              </div>
            ))}
          </div>
        );
      }
  }
  

export class ScrollPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          };
      }
      render() {
        const {children, item} = this.props;
        return (
                <Tilt options={defaultOptions2} className="ScrollCard">
                    <div className="d-flex flex-column">
                        <h4 className="ScrollYear">{item.year}</h4>
                        <div className="ScrollContent">
                            <span className="ScrollTitle">{item.title}</span>
                            <p className="ScrollText">{item.text}</p>
                        </div>
                    </div>
                    {
                        item.content!==''&&
                        <a href={""} className="tilterLink" target="_blank">Подробно</a>
                    }
                </Tilt>
        );
      }  
}