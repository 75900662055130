import React from "react";
import {ScrollContainer, ScrollPage} from "./scroll-motion";
import {timelines} from './data'
import { useLocation, Link } from 'react-router-dom'
import { FileUploader } from "react-drag-drop-files";
import Swal from 'sweetalert2'


const fileTypes = ["PDF", "DOC", "DOCX"];

class Career extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        data:
          {
            "id": 1,
            "position": "Programmist",
            "title": "Front-end developer",
            "is_active": true,
            "requirement": "<p>Junior maoshiga ishlaydigan Senior dasturchi kerak!!!</p>",
            "brand": 2,
            "image": 'https://dpnsee.org/wp-content/uploads/2020/01/Job-vacancy.jpg',
        },
      file: null,    
      };
  }

  componentDidMount() {

  }
  
  handleChange = (file) => {
    this.setState({file:file});
  };
  handleSubmit = () => {
    const {file} = this.state;
    if(file!=null){
      Swal.fire({
        title: 'Резюме принято',
        text: 'Пожалуйста, проверьте свою электронную почту для дальнейшего ответа',
        icon: 'success',
    });
    }
    else{
      Swal.fire({
        title: 'Ошибка',
        text: 'Пожалуйста, загрузите файл PDF, DOC или DOCX',
        icon: 'error',
    });      
    }
  };
  componentWillUnmount() {
  }
  render() {
    const {data} = this.state;
    return (
        <div className="content container">
          <h2 className="blocktitle mb-2 pb-4">{data.title}</h2>
          <p className="card-title mb-2 pb-4">{data.position}</p>
          <div dangerouslySetInnerHTML={{__html:data.requirement}}></div>
          <div className="d-flex flex-nowrap justify-content-center">
            <div className="d-flex flex-column justify-content-center" style={{gap:'20px'}}>
            <FileUploader handleChange={this.handleChange} name="file" types={fileTypes} classes="drag"/>
            <button variant="contained" onClick={this.handleSubmit}>Отправить</button>
            </div>
          </div>
        </div>
    );
  }
}

export default Career;
