import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Cookies from "universal-cookie";
import * as en from "./assets/static/js/en/translation.json";
import * as ru from "./assets/static/js/ru/translation.json";

const cookies = new Cookies();

if (cookies.get("Language")) {
  i18n.init({
    lng: cookies.get("Language"),
    fallbackLng: cookies.get("Language"),
  });
} else {
  i18n.init({ lng: "ru", fallbackLng: "ru" });
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next

  .init({
    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
    },

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
