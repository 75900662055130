import React from 'react';
import Header from './components/header';
import * as THREE from "three";
import WAVES from 'vanta/dist/vanta.waves.min.js';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Main from './components/main';
import Horizontal from './components/horiozontal';
import Contacts from './components/contacts';
import Inner from './components/inner';
import SliderContent from './components/slider-content';
import Content from './components/content';
import Careers from './components/careers';
import Career from './components/career';
import News from './components/news';
import New from './components/new';

import {horizontalData} from './components/data'
import Dealers from './components/dealers';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.vantaRef = React.createRef();
    this.state = {
    }
  }
  changeCurrent(i){
    this.setState({current:i});
  }
  componentDidMount() {
    this.vantaEffect = WAVES({
      el: this.vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: '#0c214d',
      shininess: 300,
      waveHeight: 15,
      waveSpeed: 1,
      zoom: 1,
    });

    window.addEventListener('wheel', this.handleScroll);
    
    this.setState({data:horizontalData});
  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy();
  }
  render(){
    return (
      <Router>
        <div className="App">
          <div className="vantaRef" ref={this.vantaRef}></div>
          <Header/>
          <Switch>
            <Route exact path="/" component={Horizontal} setcolor={this.setcolor}/>
            <Route exact path="/contacts" component={Contacts} />
            {/* <Route exact path="/inner/:id" component={Inner} /> */}
            <Route exact path="/inner/:id" component={SliderContent} />
            <Route exact path="/content/:id" component={Content} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/career/:id" component={Career} />
            <Route exact path="/news" component={News} />
            <Route exact path="/news/:id" component={New} />
            <Route exact path="/dealers" component={Dealers} />
          </Switch>
          {/* <Horizontal/> */}
        </div>
      </Router>
    );
  }
}

export default App;
