import React from "react";
import logo from "../logo.svg";
import Cookies from "universal-cookie";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

const cookies = new Cookies();
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "ru";
    }
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  handleLanguage = (lng) => {
    i18n.changeLanguage(lng);
    cookies.set("Language", lng);
    window.location.reload(false);
  };
  render() {
    const { menu } = this.state;
    const { t } = this.props;
    return (
      <div className="header d-flex flex-nowrap justify-content-between">
        <Link to="/">
          <img src={logo} className="logo" />
        </Link>
        <div className="right-nav d-flex flex-nowrap justify-content-center align-items-center">
          <h4 className="mobile-hide">
            <a href="tel:+998557010404" className="link">
              +998 (55) 701-04-04
            </a>
          </h4>
          <span
            className={`link ${
              this.lang === "ru" ? "active-link" : "passive-link"
            }`}
            onClick={this.handleLanguage.bind(this, "ru")}
          >
            ru
          </span>
          <span
            className={`link ${
              this.lang === "en" ? "active-link" : "passive-link"
            }`}
            onClick={this.handleLanguage.bind(this, "en")}
          >
            en
          </span>
          <a
            className="link mobile-hide"
            href="https://www.facebook.com/saggilamlari/"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            className="link mobile-hide"
            href="https://www.instagram.com/saggilamlari/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <div
            id="menu-button"
            className="menu-button-outer"
            onClick={this.toggleMenu}
          >
            <a
              href="#"
              id="menu-button-a"
              className={menu ? "forbidden-hover close" : ""}
            >
              <div>
                <span className="span-1"></span>
                <span className="span-2"></span>
                <span className="span-3"></span>
              </div>
            </a>
          </div>
        </div>
        <div
          id="main-menu"
          className={menu ? "show prepare-show" : "hide prepare-hide"}
        >
          <table className="menu-table">
            <tr>
              <td>
                <span className="menu-numbers">01</span>
              </td>
              <td>
                <Link
                  to="../"
                  className="menu-links link"
                  onClick={this.toggleMenu}
                >
                  <span>{t("main.text")}</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <span className="menu-numbers">02</span>
              </td>
              <td>
                <Link
                  to={"../news"}
                  className="menu-links link"
                  onClick={this.toggleMenu}
                >
                  <span>{t("press.text")}</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <span className="menu-numbers">03</span>
              </td>
              <td>
                <Link
                  to={"../careers"}
                  className="menu-links link"
                  onClick={this.toggleMenu}
                >
                  <span>{t("career.text")}</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <span className="menu-numbers">04</span>
              </td>
              <td>
                <Link
                  to={"../contacts"}
                  className="menu-links link"
                  onClick={this.toggleMenu}
                >
                  <span>{t("contacts.text")}</span>
                </Link>
              </td>
            </tr>
            <tr>
              <td>
                <span className="menu-numbers">05</span>
              </td>
              <td>
                <a
                  href="https://procurement.sag.uz/"
                  className="menu-links link"
                  onClick={this.toggleMenu}
                >
                  <span>{t("purchase.text")}</span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <span className="menu-numbers">06</span>
              </td>
              <td>
                <a
                  href="../dealers"
                  className="menu-links link"
                  onClick={this.toggleMenu}
                >
                  <span>{t("dealers.text")}</span>
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Header);
