import React from "react";
import { TextField, Button } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";
import FacebookIcon from "@mui/icons-material/Facebook";
import Link from "@material-ui/core/Link";
import withStyles from "@mui/material";
import { withTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { createRef } from "react";
import Notification from "./notification";

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: window.innerWidth,
      notificationMessage: null,
    };

    this.nameInputRef = createRef();
    this.emailInputRef = createRef();
    this.phoneInputRef = createRef();
    this.commentInputRef = createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ screen: window.innerWidth });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    const nameValue = this.nameInputRef.current.value;
    const emailValue = this.emailInputRef.current.value;
    const phoneValue = this.phoneInputRef.current.value;
    const commentValue = this.commentInputRef.current.value;
    this.setState({ notificationMessage: null });
    emailjs
      .send(
        "service_hpvmbbi",
        "template_kqdrbvj",
        {
          from_name: nameValue,
          from_phone: phoneValue,
          to_name: "sag.uz",
          from_email: emailValue,
          to_email: "marketerofsag@gmail.com",
          message: commentValue,
        },
        "mGbe3PxQAYcJ7wSu1"
      )
      .then(
        () => {
          this.setState({
            notificationMessage: `${this.props.t("notificationMessageSuccess.text")}`,
          });

          this.nameInputRef.current.value = "";
          this.emailInputRef.current.value = "";
          this.phoneInputRef.current.value = "";
          this.commentInputRef.current.value = "";
        },
        (error) => {
          this.setState({
            notificationMessage: `${this.props.t("notificationMessageFail.text")}`,
          });

          this.nameInputRef.current.value = "";
          this.emailInputRef.current.value = "";
          this.phoneInputRef.current.value = "";
          this.commentInputRef.current.value = "";
        }
      );
  };
  render() {
    const { t } = this.props;
    const { screen, notificationMessage } = this.state;
    return (
      <div
        className={
          screen > 1400
            ? `contacts content item active`
            : `contacts content item active container`
        }
      >
        <div className="content-text d-flex flex-wrap justify-content-between">
          <div className="contactsblock d-flex flex-column">
            <h2>{t("contacts.text")}</h2>
            <div className="text mb-4">
              <p>{t("address_1.text")}</p>
              <p>{t("address_2.text")}</p>
            </div>
            <div className="phone mb-4">
              <p>
                <a href="mailto:marketing@sag.uz" className="link">
                  marketing@sag.uz
                </a>
              </p>
              <a href="tel:+998557010404" className="link">
                +998 (55) 701-04-04
              </a>
            </div>
            <div className="socials d-flex flex-wrap">
              <Link
                href="https://www.youtube.com/c/SAGgilamlari"
                className="me-2"
                target="_blank"
              >
                <YouTubeIcon sx={{ color: "white" }} />
              </Link>
              <Link
                href="https://www.instagram.com/saggilamlari/"
                className="me-2"
                target="_blank"
              >
                <InstagramIcon sx={{ color: "white" }} />
              </Link>
              <Link
                href="https://www.facebook.com/saggilamlari/"
                className="me-2"
                target="_blank"
              >
                <FacebookIcon sx={{ color: "white" }} />
              </Link>
              <Link href="https://t.me/samantepgilam" target="_blank">
                <TelegramIcon sx={{ color: "white" }} />
              </Link>
            </div>
          </div>
          <form onSubmit={this.handleSubmit} className="d-flex flex-column contactsForm">
            <TextField
              id="standard-basic"
              label={t("name.text")}
              variant="standard"
              className="contactsInput mb-4"
              fullWidth
              sx={{
                input: { color: "white" },
                label: { color: "white" },
                border: { color: "white" },
              }}
              inputRef={this.nameInputRef}
              required
            />
            <TextField
              type="email"
              id="standard-basic"
              label={t("email.text")}
              variant="standard"
              className="contactsInput mb-4"
              fullWidth
              sx={{ input: { color: "white" }, label: { color: "white" } }}
              inputRef={this.emailInputRef}
              required
            />
            <TextField
              type="tel"
              id="standard-basic"
              label={t("phone.text")}
              variant="standard"
              className="contactsInput mb-4"
              fullWidth
              sx={{ input: { color: "white" }, label: { color: "white" } }}
              inputRef={this.phoneInputRef}
            />
            <TextField
              label={t("comments.text")}
              className="contactsInput mb-4"
              fullWidth
              sx={{ input: { color: "white" }, label: { color: "white" } }}
              focused
              inputRef={this.commentInputRef}
              required
            />
            <Button
              variant="outlined"
              fullWidth
              sx={{ color: "white" }}
              type="submit"
            >
              {t("send.text")}
            </Button>
            {notificationMessage && (
              <Notification message={notificationMessage} />
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Contacts);
