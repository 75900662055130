import React from "react";
import {ScrollContainer, ScrollPage} from "./scroll-motion";
import {timelines} from './data'
class Inner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
  }

  componentWillUnmount() {
  }
  render() {
    return (
        <div className="inner content">
            <ScrollContainer 
            offset={1000}
            startDelta={0}
            perspective={1000}
            >
              {
                timelines&&
                timelines.map((timeline, i)=>(
                  <ScrollPage item={timeline} key={i}/> 
                ))
              }           

            </ScrollContainer>
        </div>
    );
  }
}

export default Inner;
