import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Slider from "react-slick";
import { data } from "./data";
import Cookies from "universal-cookie";

const cookies = new Cookies();
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      screen: window.innerWidth,
      lang: 'ru',
    };
    this.lang= 'ru';
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "ru";
    }
  }
  componentDidMount() {
    this.setState({ data: data, lang: this.lang });
    window.addEventListener("resize", this.handleResize);

  }
  componentWillUnmount(){
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({ screen: window.innerWidth });
  };
  handleBeforeChange = (current, next) => {
    this.setState({ activeSlide: next });
  };
  render() {
    const { data, activeSlide, screen, lang } = this.state;
    var settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 750,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: false,
      draggable: false,
      autoplay: true,
      autoplaySpeed: 5000,
      appendDots: (dots) => (
        <div>
          <div className="dots-container"> {dots} </div>
        </div>
      ),
      customPaging: function (i) {
        return (
          <a className="text-controls">
            {data[i]["subtitle"] && (
              <div>
                {
                  screen < 850 &&
                  <span className="counter">0{i+1}</span>
                }
                <div className="text text-item text-controls-item-0 mobile-hide">
                  {data[i]["subtitle"][lang]}
                </div>
              </div>
            )}
          </a>
        );
      },
      beforeChange: this.handleBeforeChange,
    };
    return (
      (
        <div className="main content">
          <div className="mainSlider">
            <Slider {...settings}>
              {data &&
                data.map((item, i) => (
                  <div
                    className={`item ${activeSlide === i ? "active" : ""}`}
                    key={i}
                  >
                    <div className="content-text d-flex flex-column justify-content-center">
                      <h2>
                        {item.title[this.lang]} <b>{item.blue[this.lang]}</b>
                      </h2>
                      <div className="text">
                        <p>{item.text[this.lang]}</p>
                        <h4></h4>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      )
    );
  }
}
export default Main;
